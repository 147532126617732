<template>
  <div>
    <label  class="editor-label">Click Event:</label>
    <div class="d-flex align-items-center" style="gap: 4px">
      <div>
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >

          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M14 12C14 14.7614 11.7614 17 9 17H7C4.23858 17 2 14.7614 2 12C2 9.23858 4.23858 7 7 7H7.5M10 12C10 9.23858 12.2386 7 15 7H17C19.7614 7 22 9.23858 22 12C22 14.7614 19.7614 17 17 17H16.5"
              stroke="#B3AFB6"
              stroke-width="2"
              stroke-linecap="round"
            ></path>
          </g>
        </svg>
      </div>

      <div
        class="w-100 editor-font-family position-relative d-flex align-items-center"
      >
      <select
          v-model="internalValue"
          name="font-family"
          ref="fontFamilySelect"
          
          class="editor-input-control w-100 m-0 position-relative"
        >
        <option value="restart" selected>Restart Quiz</option>
            <option value="addAllToCart" v-if="isBearWithBenefitsClient" >Add all to Cart</option>
            <optgroup label="Go to URL:">
              <option value="goToUrl">New Tab</option>
            <option value="goToCurrentTab">Current Tab</option>
            </optgroup>
            <optgroup label="Send Email:" v-if="isBauerfeindClient">
              <option value="mailToOwner">To Owner</option>
            <option value="mailToCustomer">Customer</option>
            </optgroup>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import fontFamilyList from "../../../staticPages/fontFamilyList.js";

export default {
  props: {
    value: String, // Use "value" prop in Vue 2
    isBauerfeindClient:Boolean
  },
  computed: {
    getFontFamilyList() {
      return fontFamilyList;
    },
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style scoped>
</style>
